import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import style from "./contact-form.module.css";

const ContactForm = ({ closeModal }) => {
  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORM_ID);
  return (
    <div className={style.modal}>
      <div className={style.modalContent}>
        <span className={style.close} onClick={closeModal}>
          &times;
        </span>
        <h2 className={style.title}>Contact Me</h2>
        {state.succeeded ? (
          <p className={style.successMessage}>
            Thanks for your message! I'll get back to you shortly.
          </p>
        ) : (
          <form onSubmit={handleSubmit} className={style.contactForm}>
            <label htmlFor="name" className={style.formLabel}>
              Name
            </label>
            <input
              id="email"
              type="name"
              name="name"
              className={style.formInput}
            />
            <ValidationError
              prefix="name"
              field="name"
              errors={state.errors}
              className="form-error"
            />
            <label htmlFor="email" className={style.formLabel}>
              Email Address
            </label>
            <input
              id="email"
              type="email"
              name="email"
              className={style.formInput}
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
              className="form-error"
            />
            <label htmlFor="message" className={style.formLabel}>
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className={style.formTextarea}
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
              className="form-error"
            />
            <button
              type="submit"
              disabled={state.submitting}
              className={style.formButton}
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
