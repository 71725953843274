import React, { useState } from "react";
import SEO from "../../components/helmet/seo";
import style from "./home.module.css";
import ContactForm from "../../components/form/contact-form";
import { trackEvent } from "../../services/analytics";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pageSEODetails = {
    title: "PedroHMA",
    description:
      "Pedro Henrique de Miranda Arthur is a Software Engineer based in Los Angeles, balancing code with guitar chords, grappling on the mat, experimenting in the kitchen, and exploring the world.",
    name: "Pedro Henrique de Miranda Arthur",
    type: "website",
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const hireMe = () => {
    window.open("https://hireme.pedrohma.com", "_blank", "noopener,noreferrer");
    trackEvent("Hire Me", "Click", "Hire Me Button");
  };

  return (
    <div className={style.home}>
      <SEO {...pageSEODetails} />
      <div className={style.centeredContent}>
        <h1>Pedro Henrique de Miranda Arthur</h1>
        <h2 className={style.subtitle}>
          Software Engineer based in Los Angeles.
        </h2>
        <h3 className={style.description}>
          Balancing code with guitar chords, grappling on the mat, experimenting
          in the kitchen, and exploring the world.
        </h3>
        <div className={style.contact_me_container}>
          <span className={style.links}>
            <button className={style.contact_me} onClick={openModal}>
              Contact Me
            </button>
          </span>
          <span className={style.links}>
            <button className={style.hire_me} onClick={hireMe}>
              Hire Me
            </button>
          </span>
        </div>
      </div>
      {isModalOpen && <ContactForm closeModal={closeModal} />}
    </div>
  );
};

export default Home;
