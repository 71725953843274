import { HelmetProvider } from "react-helmet-async";
import AppRoutes from "../router/app-routes";
import "./App.css";

import { useEffect } from "react";
import { initializeAnalytics, trackPageView } from "../services/analytics";

initializeAnalytics();

const helmetContext = {};
function App() {
  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);
  return (
    <HelmetProvider context={helmetContext}>
      <AppRoutes />
    </HelmetProvider>
  );
}

export default App;
