import ReactGA from "react-ga4";

export const initializeAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
};

export const trackPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page: page });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};
